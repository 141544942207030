@media only screen and (max-width: 575px) {
  .contenedor-logo {
    display: none;
  }
  .recaptcha {
    transform:scale(0.77);
    transform-origin:0 0;
    }
}

.logo{
    /* margin-top: 40%; */
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 85%;
}

.card-body-logo{
  background-color: #46546C;
}