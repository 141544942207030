// Here you can add other styles
table > *{
    font-size: 14px;
}

.td-editar, .td-delete{
    width: 1%;
}
.react-select > div {
    margin: -1px;
}

#linkDescargaArchivo {
  color: blue;
  text-decoration: underline;
  cursor: pointer
}
